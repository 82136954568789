.container {
    position: relative;
    overflow: hidden;
  }
  
  .wrapper {
    position: absolute;
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  
  .reactDraggable {
    position: absolute;
    bottom: 10%;
    left: 50%;
  }
  
  .content {
    position: absolute;
    top: 0;
    left: 0;
    cursor: col-resize;
  }
  
  .content, .before, .after {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .before {
    z-index: -1;
  }
  
  .afterWrapper {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    overflow: hidden;
  }
  
  .handle {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: -1px;
    width: 2px;
    background: #fff;
  }